<template>
    <div class="container">
      <div class="container-content">
        <div class="container-content-home">
            <div class="content-home-bg">
              <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/header.png"/>
            </div>

            <!-- 没有数据的展示 -->
            <div class="content-home-list" v-if="list.length == 0">
              <div class="noData">
                <img class="noDataImg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/noData.png">
                <div class="noDataText">您还没有在约会员哦</div>
              </div>
            </div>

            <!-- 有数据的展示 -->
            <div class="content-home-list" v-else>
              <div class="home-list-item">
                <div class="item-card" v-for="vip in list" :key="vip.title" v-if="vip.title === 'AI兔会员'"
                @click="toGetVipItem(vip.title)">
                  <div class="left-wrap">
                    <img class="logo" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/ceshi_logo1.png">
                    <div class="content">
                      <span class="title">AI兔视听会员</span>
                      <span class="info">好礼N选</span>
                    </div>
                  </div>
                  <div class="right-arrow">
                    <img class="arrow" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/arrow.png">
                  </div>
                </div>
                <div class="item-card" v-for="vip in list" :key="vip.title" v-if="vip.title === 'AI兔超级会员'"
                @click="toGetVipItem(vip.title)">
                  <div class="left-wrap">
                    <img class="logo" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/ceshi_logo2.png">
                    <div class="content">
                      <span class="title">视听甄选会员</span>
                      <span class="info">好礼N选+5元话费券</span>
                    </div>
                  </div>
                  <div class="right-arrow">
                    <img class="arrow" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/arrow.png">
                  </div>
                </div>
              </div>
            </div>

        </div>
        <!-- <div v-if="tabbarIndex == 1" class="container-content-mine">
          <div class="mine-head">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_bg.png"/>
            <div class="mine-head-info">
              <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/mine_head.png"/>
              <div>15112121212</div>
            </div>
          </div>
          <div class="mine-content">
            <div class="mine-content-item" @click="settingItem(index)" v-for="item,index in settingList" :key="index">
              <div class="item-left">
                <img :src="item.imgUrl" alt="">
                {{ item.item_name }}
              </div>
              <div class="item-right">
                <div class="item-right-phone" v-if="index == 3">{{ item.item_num }}</div>
                <img v-else src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/right.png"/>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="container-footer">
        <div class="container-footer-btn" @click="changeTab(index)" :class="tabbarIndex == index ? 'active' : ''" v-for="item,index in tabbarList" :key="index">
          <img class="footer-btn-img"  :src="tabbarIndex == index ? item.fillimg : item.img"/>    
          {{ item.name }}
        </div>
      </div> -->
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import { isWeixin, setAppName } from "@/utils/common";
  import { wechatLogin, getWechatopenid } from "@/api/lky_gzh/index.js";

  export default {
    data() {
      return {
        token: "",
        auth_code: "",
        appName: "",
        login_code: "",
        wechat_open_id: ""
      };
    },
    computed: {
      ...mapGetters(["lky_token"]),
      ...mapGetters(["lky_user_info"]),

      list() {
        const data = {
          领取权益: {
            page: "/lky_gzh/record/index",
          },
        };

        let vipList = this.lky_user_info.available_vip;
        return vipList.map((i) => ({
          ...data[i],
          title: i,
        }));
      },
    },
    created() {
      console.log(this.list);
      const { code = "" } = this.$route.query;
      this.login_code = code;


      if(isWeixin()){//微信环境自动登录
        if(!this.lky_token){
          this.getOpenid();
        }else{
          this["lky/getUserInfo"]()

          this["lky/getUserInfo"]().then((res)=>{
            console.log("res = ",res)
            if(res == undefined){
              this["lky/SET_TOKEN"]();
              const { code = "" } = this.$route.query;
              this.login_code = code;
              // 这里应该判断如果getUserInfo是403的话应该执行this.getOpenid();
              this.getOpenid();
            }
          })
        }
      }else{//其他环境跳转手机验证码登录
        if(!this.lky_token){
          this.$router.push("/lky_gzh/receive/login");
        }else{
          this["lky/getUserInfo"]();

          this["lky/getUserInfo"]().then((res)=>{
            console.log("res = ",res)
            if(res == undefined){
              this.$router.push({
                path: "/lky_gzh/receive/login"
              });
            }
          })
        }
      }
    },
    methods: {
      ...mapMutations(["lky/SET_TOKEN"]),
      ...mapMutations(["lky/SET_USER_INFO"]),
      ...mapMutations(["lky/SET_WECHAT_OPEN_ID"]),
      ...mapActions(["lky/getUserInfo"]),

      // 领取权益
      toGetVipItem(name){
        this.$router.push({
          path: "/lky_gzh/record/index",
          // path: "/lky_gzh/record/break",
          query: {
            type: name
          }
        });
      },

      getOpenid(){
        const urlCode = new URLSearchParams(window.location.search).get("code");
        if (!urlCode && !this.lky_token) {
          let redirectUri = encodeURIComponent(`${location.href}`);
          this["lky/SET_TOKEN"]("");
          const appId = "wx76a9b6237619fca2";
          const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
          window.location.replace(url); 
          // this.$router.push(url);
          return;
        }

        getWechatopenid({login_code: this.login_code}).then((res)=>{
          if(res.code == 0){
            this.wechat_open_id = res.wechat_open_id;
            this["lky/SET_WECHAT_OPEN_ID"](res.wechat_open_id);
            this.initLogin();
          }else{

          }
        })
      },

      async initLogin() {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await wechatLogin({ wechat_open_id: this.wechat_open_id });
        console.log(res);
        this.$toast.clear();
        if (res.code == 0) {
          this["lky/SET_TOKEN"](res.token);
          this["lky/getUserInfo"]();
        } else {
          // 使用短信验证码登录
          this.$toast.fail(res.message);
          this.$router.push({
              path: "/lky_gzh/receive/login",
              query: {
                wechat_open_id: this.wechat_open_id,
              },
            });
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #F5F5F5;
    .container-content{
      width: 100%;
      height: calc(100% - 110px);
      .container-content-home{
        background-color: #F7F7F7;
        width: 100%;
        height: 100%;
        .content-home-bg{
          width: 100%;
        //   height: 430px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .content-home-list{
          position: relative;
          .home-list-item{
            position: absolute;
            top: -100px;
            left: 0;
            right: 0;
          }
          .item-card{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 13.953px;
            background: #FFF;
            box-shadow: 0px 1.744px 3.488px 0px rgba(0, 0, 0, 0.15);
            padding: 40px 10px;
            margin: 0 30px 30px 30px;
          }
          .left-wrap{
            display: flex;
            align-items: center;
            .logo{
              width: 100px;
              margin: 0 30px;
            }
            .content{
              display: flex;
              flex-direction: column;
              .title{
                color: #000;
                font-size: 36px;
                padding: 5px 0;
              }
              .info{
                color: #888;
                font-size: 24px;
                padding: 5px 0;
              }
            }
          }
          .right-arrow img{
            width: 50px;
            height: 50px;
            margin-right: 50px;
          }

          .noData{
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .noDataImg{
              width: 320px;
            }
            .noDataText{
              color: #888;
              text-align: center;
              font-size: 24px;
            }
          }
        }
        // .content-home-list{
        //   width: 100%;
        //   height: calc(100% - 340px);
        //   position: relative;
        //   .home-list-item{
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     top: -100px;
        //     overflow-y: scroll;
        //     .item-card{
        //       width: 666px;
        //       height: 257px;
        //       margin: 0 auto 10px;
        //       position: relative;
        //       img{
        //         width: 100%;
        //         height: 100%;
        //       }
        //       .left-icon{
        //         width: 128px;
        //         height: 42px;
        //         position: absolute;
        //         left: 41px;
        //         top: 37px;
        //         img{
        //           width: 100%;
        //           height: 100%;
        //         }
        //       }
        //       .center-icon{
        //         width: 190px;
        //         height: 175px;
        //         position: absolute;
        //         right: 48px;
        //         top: 0px;
        //         img{
        //           width: 100%;
        //           height: 100%;
        //         }
        //       }
        //       .center-btn{
        //         width: 172px;
        //         height: 60px;
        //         position: absolute;
        //         right: 59px;
        //         bottom: 44px;
        //         background: linear-gradient( 136deg, #803F32 0%, #FF9F67 100%);
        //         border-radius: 30px;
        //         font-weight: 500;
        //         font-size: 26px;
        //         color: #FFCAB1;
        //         line-height: 60px;
        //         text-align: center;
        //         font-style: normal;
        //       }
        //       .right-top{
        //         position: absolute;
        //         right: 0;
        //         top: 0px;
        //         width: 100px;
        //         height: 46px;
        //         background: #FFFFFF;
        //         border-radius: 0px 23px 0px 23px;
        //         opacity: 0.35;
        //         font-weight: 400;
        //         font-size: 24px;
        //         color: #A57B4D;
        //         line-height: 46px;
        //         text-align: center;
        //       }
        //     }
        //   }
        // }
        
      }
      .container-content-mine{
        width: 100%;
        height: 100%;
        position: relative;
        .mine-head{
          width: 100%;
          height: 390px;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          .mine-head-info{
            width: 100%;
            height: 105px;
            position: absolute;
            bottom: 100px;
            box-sizing: border-box;
            padding-left: 40px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            img{
              width: 105px;
              height: 100%;
              margin-right: 20px;
            }
          }
        }
        .mine-content{
          width: 690px;
          // height: 439px;
          background: #FFFFFF;
          border-radius: 24px;
          position: absolute;
          top: 320px;
          left: 50%;
          transform: translateX(-50%);
          .mine-content-item{
            width: 100%;
            height: 100px;
            box-sizing: border-box;
            padding: 0 30px;
            .item-left{
              width: 50%;
              height: 100%;
              float: left;
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 32px;
              color: #666666;
              img{
                width: 34px;
                height: 34px;
                margin-right: 20px;
              }
            }
            .item-right{
              height: 100%;
              float: right;
              display: flex;
              align-items: center;
              .item-right-phone{
                width: 240px;
                text-align: right;
                color: #999999;
              }
              img{
                width: 44px;
                height: 44px;
              }
            }
          }
        }
      }
    }
    .container-footer{
      width: 100%;
      height: 100px;
      position: absolute;
      bottom: 10px;
      border-top: 1px solid #F1F1F1;
      .container-footer-btn{
        width: 50%;
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 20px;
        .footer-btn-img{
          width: 46px;
          height: 46px;
          
        }
      }
      .active{
        color: #FF5000;
      }
    }
   
  }
  </style>  